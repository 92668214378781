import * as React from "react"
import { Link } from "gatsby"

const notFound = {
  padding: "60px 0",
  height: "100vh",
  width:"100%",
  textAlign: "center"
}

// markup
const NotFoundPage = () => {
  return (
    <main>
      <section style={notFound}>
        <div className="container">
          <title>Not found</title>
          <h1>Page not found</h1>
        </div>
      </section>
    </main>
  )
}

export default NotFoundPage
